import React, { useEffect, useState, useRef } from "react"
import { CloudinaryContext } from "cloudinary-react"
import axios from "axios"
import InfiniteScroll from "react-infinite-scroller"
import GalleryImg from "./GalleryImg"
import { Row } from "react-bootstrap"
import { Assets } from "../../assets"

const imgNum = 20

export const Gallery = props => {
  const data = useRef([])
  const hasMore = useRef(true)
  const imgLoaded = useRef(0)

  const [images, setImages] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get("https://res.cloudinary.com/semte/image/list/galeria.json")
      .then(res => {
        data.current = res.data.resources
        setLoading(false)
      })
  }, [])

  const loadItems = pageNumber => {
    let nextData = data.current
      .slice(imgLoaded.current, imgLoaded.current + imgNum)
      .map((data, index) => (
        <GalleryImg data={data} key={index + imgLoaded.current} />
      ))

    console.log(pageNumber)

    setImages([...images, ...nextData])

    imgLoaded.current = imgLoaded.current + nextData.length
    if (imgLoaded.current >= data.current.length) {
      hasMore.current = false
    }
  }

  const loader = <div className="loader">Loading ...</div>

  return (
    <CloudinaryContext
      cloudName="semte"
      style={{
        paddingBottom: "100px",
        height: "100%",
        overflow: "auto",
      }}
    >
      {!isLoading && (
        <InfiniteScroll
          className="gallery"
          pageStart={0}
          loadMore={loadItems.bind(this)}
          loader={loader}
          hasMore={hasMore.current}
          threshold={0}
        >
          {images}
        </InfiniteScroll>
      )}

      {!hasMore.current && (
        <Row className="justify-content-center display-flex">
          <img
            className="arrowUp"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            alt="Arrow to scroll top"
            src={Assets.arrowUp}
          />
        </Row>
      )}
    </CloudinaryContext>
  )
}
