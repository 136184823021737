import React from "react"
import { Image, Transformation } from "cloudinary-react"

export default function GalleryImg(props) {
  return (
    <div className="imageContainer">
      <Image
        publicId={props.data.public_id + ".webp"}
        className="galleryImg"
        alt="gallery image"
      >
        <Transformation
          quality="40"
          width="600"
          aspect_ratio="1.5"
          crop="fill"
          gravity="center"
        />
      </Image>
    </div>
  )
}
