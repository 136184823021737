import React from "react"
import { Layout } from "../components/Layout"
import { Gallery } from "../components/Gallery/Gallery"

export default function galeria() {
  return (
    <Layout Jumbo={true}>
      <Gallery />
    </Layout>
  )
}
